import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AllyaFeatureFlagsDto } from '@core/services/dtos/allya-feature-flags.dto';
import { AllyaSettingsDto } from '@core/services/dtos/allya-settings.dto';
import { BaseSettingService } from '@portal-shared/app/services/base-settings.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AllyaSettingService extends BaseSettingService<AllyaFeatureFlagsDto, AllyaSettingsDto> {
  public constructor(http: HttpClient) {
    super(environment, '/assets/allya-settings.json', http);
  }
}
