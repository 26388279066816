import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalGroupDto } from '../dtos/portal-group.dto';
import { PortalCacheKeys } from '../enums/portal-cache-keys.enum';
import { HttpCacheService } from './http-cache.service';

@Injectable({
  providedIn: 'root'
})
export class PortalGroupService extends HttpCacheService {
  private static adminGroupName = 'Administrators';

  public constructor(http: HttpClient) {
    super(http, '/api/groups');
  }

  public async amIAnAdmin() {
    const myGroups = await this.getMyGroups();
    return myGroups.some(g => g.name === PortalGroupService.adminGroupName);
  }

  public getMyGroups() {
    return this.query('me').withCache(PortalCacheKeys.groups).get<PortalGroupDto[]>();
  }
}
