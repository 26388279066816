import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'al-logo',
  templateUrl: './allya-logo.component.html',
  styleUrls: ['./allya-logo.component.scss']
})
export class AllyaLogoComponent {
  @Input() public colored = false;
}
