<svg version="1.1"
     id="svg-logo"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     x="0px"
     y="0px"
     viewBox="0 0 332.8 153.3"
     style="enable-background:new 0 0 332.8 153.3;"
     xml:space="preserve"
     class="logo"
     [class.colored]="colored">
  <g>
    <path class="text-color"
          d="M45.6,30.4C20.4,30.4,0,50.9,0,76s20.5,45.6,45.6,45.6c12.3,0,23.4-4.9,31.6-12.8v12.8h14V76
		C91.3,50.9,70.8,30.4,45.6,30.4z M45.6,107.7C28.2,107.7,14,93.5,14,76.1s14.2-31.6,31.6-31.6s31.6,14.2,31.6,31.6
		S63.1,107.7,45.6,107.7z" />
    <rect x="105.2"
          y="10.2"
          class="bar-chart-color-1"
          width="14"
          height="111.5" />
    <rect x="133.2"
          class="bar-chart-color-2"
          width="14"
          height="121.7" />
    <g>
      <path class="text-color"
            d="M332.7,76.2L332.7,76.2c0-25.1-20.4-45.5-45.4-45.5c-14.4,0-34.4,6.1-45,35.4c-6.3,17.4-12.7,36.5-28.4,40.6
			c-2.4,0.6-4.8,0.8-7.1,0.8c-17.2,0-31.3-13.9-31.5-31.1l0,0V18.6h-14v57.9c0.2,24.9,20.5,45.1,45.4,45.1c5.6,0,11.1-1.1,16.3-3.1
			l-6.3,16.7c-0.3,0.6-2.4,4.1-7.2,4.1h-16.1v14h16.1c11.5,0,18-7.9,20-12.6l24.1-64.1h-0.1c5.6-15.1,13-31.5,33.7-31.9
			c17.3-0.3,31.5,14.1,31.5,31.5c0,15-10.5,27.5-24.6,30.7c-2.1,0.5-4.3,0.7-6.6,0.7c-13.9,0-25.3-9-29.8-21.5h-0.1
			c-3.1,9.9-6.5,15.7-7,16.9c0,0.1,0,0.2,0,0.2c6.9,10.4,21.5,18.9,36.8,18.5c1.2,0,2.3-0.1,3.5-0.2c0.4,0,1.4-0.1,1.5-0.1
			c0.7-0.1,1.5-0.2,2.2-0.3c0,0,1.4-0.2,2.1-0.4c0.1,0,0.3-0.1,0.3-0.1c8.4-1.9,15.9-6,21.8-11.8v12.9h14L332.7,76.2L332.7,76.2z" />
      <path class="text-color"
            d="M235.4,63.8c3.2-8.8,7.2-15.7,11.5-21.1V30.4h-13.4v38.3c0.4-1.1,0.8-2.3,1.2-3.4L235.4,63.8z" />
    </g>
  </g>
</svg>
